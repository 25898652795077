body {
    cursor: url(cursors/1-1.cur), default;
}

///////////// SeachBar‘s cursor //////////
.searchbar.google-searchbar {
    cursor: url(cursors/12.cur),text;
    
    .buttons
    * {
        cursor: url(cursors/12.cur),pointer;
    }
}
.google-definition {
    #listen:hover {
        cursor: url(cursors/12.cur),pointer;
    }
}

.card {
    cursor: url(cursors/12.cur),pointer;
}

.language-select #language-protip {
    #close-language-protip{
        cursor: url(cursors/12.cur),pointer;
    }
}

.select-wrapper {
    select {
        cursor: url(cursors/12.cur),pointer;
    }
}

.nose-button {
    cursor: url(cursors/12.cur),pointer;
}

///////// music palyer container‘s cursor /////////

.music-player-container {
    .music-player{
        .play-coontoroller {
            cursor: url(cursors/12.cur),pointer;
        }
        .music-details {
            .time-controller{
                .time-bar {
                    cursor: url(cursors/12.cur),pointer;
                }
                .volume {
                    cursor: url(cursors/12.cur),pointer;
                }
            }

        }
    }
}












div.main.end {
    cursor: none;
}